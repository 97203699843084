<template>
    <div>
 <!--    <div v-if="userData.role === 'PLSYR' && !$store.state.plasiyerCustomer">
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        <v-alert
          v-if="!$store.state.plasiyerCustomer"
          text
          outlined
          class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption"
          type="warning"
        >
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </div> -->
    <div>
        <v-row no-gutters>
            <v-col>   
               <!--  <customer-list v-if="(userData.role === 'PLSYR' || userData.role === 'FIN' ) && $vuetify.breakpoint.xsOnly" /> -->

              <v-card tile :flat="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'">
                <v-toolbar  :color="isDark ? '#312d4b' : '#fafafa'"   :flat="!$vuetify.breakpoint.smAndDown" :height="$vuetify.breakpoint.smAndDown ? 57 : 87">
                  <v-toolbar-title  class="text-body-2 text-md-h6 font-weight-medium">
                    <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                        {{ icons.mdiAccountMultiplePlus }}
                    </v-icon>
                     Aday Müşteri Oluştur
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-divider></v-divider>
            
          
                <v-form style="padding: 20px" ref="observer" @submit.prevent="sendFields" class="pa-4">
                    <v-row align="center" class="mb-0">
                      <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                        <v-subheader>Ünvan</v-subheader>          
                      </v-col>         
                      <v-col cols="12" md="6" class="pa-0">
                        <v-text-field
                          v-model="customerData.unvan"
                          outlined
                          dense
                          hide-details
                          :rules="[v => !!v || 'Bu alan zorunludur.']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
      
                    <v-row align="center" class="mb-0">
                        <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                          <v-subheader>Vergi Bilgileri</v-subheader>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" md="3" class="pa-0 pr-2">
                          <v-text-field
                            v-model="customerData.vergiNo"
                            label="Vergi No"
                            outlined
                            dense
                            hide-details
                            :rules="[v => !!v || 'Bu alan zorunludur.']"
                          ></v-text-field>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" md="3" class="pa-0 pl-2">
                          <v-text-field
                            v-model="customerData.vergiDairesi"
                            label="Vergi Dairesi"
                            outlined
                            dense
                            hide-details
                            :rules="[v => !!v || 'Bu alan zorunludur.']"
                          ></v-text-field>
                        </v-col>

                        <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" md="3" class="pa-md-0 pa-2">
                            <v-text-field
                              v-model="customerData.vergiNo"
                              label="Vergi No"
                              outlined
                              dense
                              hide-details
                              :rules="[v => !!v || 'Bu alan zorunludur.']"
                            ></v-text-field>
                          </v-col>
                          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" md="3" class="pa-md-0 pa-2">
                            <v-text-field
                              v-model="customerData.vergiDairesi"
                              label="Vergi Dairesi"
                              outlined
                              dense
                              hide-details
                              :rules="[v => !!v || 'Bu alan zorunludur.']"
                            ></v-text-field>
                          </v-col>
                      </v-row>
      
                    <v-row align="center" class="mb-0">
                      <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                        <v-subheader>Adres Satırı-1</v-subheader>
                      </v-col>
                      <v-col cols="12" md="6" class="pa-0">
                        <v-text-field
                          v-model="customerData.adres1"
                          outlined
                          dense
                          hide-details
                          :rules="[v => !!v || 'Bu alan zorunludur.']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
      
                    <v-row align="center" class="mb-0">
                      <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                        <v-subheader>Adres Satırı-2</v-subheader>
                      </v-col>
                      <v-col cols="12" md="6" class="pa-0">
                        <v-text-field
                          v-model="customerData.adres2"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
      
                    <v-row align="center" class="mb-0">
                        <!-- İl ve İlçe Bilgileri -->
                        <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                          <v-subheader>Adres Bilgileri</v-subheader>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" md="3" class="pa-0 pr-2">
                          <v-autocomplete
                            v-model="customerData.sehir"
                            label="İl"
                            :items="ilItems"
                            outlined
                            dense
                            hide-details
                            clearable
                            @change="getIlce" 
                            :rules="[v => !!v || 'Bu alan zorunludur.']"
                          ></v-autocomplete>
                        </v-col>
                        <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" md="3" class="pa-0 pl-2">
                          <v-autocomplete
                            v-model="customerData.ilce"
                            label="İlçe"
                            outlined
                            dense
                            hide-details
                            clearable
                            :items="ilceItems"
                            :rules="[v => !!v || 'Bu alan zorunludur.']"
                          ></v-autocomplete>
                        </v-col>

                        <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" md="3" class="pa-md-0 pa-2">
                            <v-autocomplete
                              v-model="customerData.sehir"
                              label="İl"
                              :items="ilItems"
                              outlined
                              dense
                              hide-details
                              clearable
                              @change="getIlce" 
                              :rules="[v => !!v || 'Bu alan zorunludur.']"
                            ></v-autocomplete>
                          </v-col>
                          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" md="3" class="pa-md-0 pa-2">
                            <v-autocomplete
                              v-model="customerData.ilce"
                              label="İlçe"
                              outlined
                              dense
                              hide-details
                              clearable
                              :items="ilceItems"
                              :rules="[v => !!v || 'Bu alan zorunludur.']"
                            ></v-autocomplete>
                          </v-col>
                      </v-row>
      
                      <v-row align="center" class="mb-0">
                        <!-- Telefon Bilgileri -->
                        <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                          <v-subheader>İletişim</v-subheader>
                        </v-col>
                        <v-col cols="12" md="3" class="pa-0 pr-2">
                          <v-text-field
                            v-model="customerData.telefon"
                            label="Telefon"
                            outlined
                            dense
                            hide-details
                            v-mask="'(###) ### ## ##'"
                            :rules="[v => !!v || 'Bu alan zorunludur.']"
                          ></v-text-field>
                        </v-col>
                      </v-row>
      
                    <v-row align="center" class="mb-0">
                      <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                        <v-subheader>Email</v-subheader>
                      </v-col>
                      <v-col cols="12" md="3" class="pa-0 pr-2">
                        <v-text-field
                          v-model="customerData.email"
                          outlined
                          dense
                          hide-details
                          :rules="[v => !!v || 'Bu alan zorunludur.']"
                        ></v-text-field>
                      </v-col>
                    </v-row>
      
                    <v-row align="center" class="mb-0">
                      <v-col cols="12" md="3" class="pa-0 d-flex align-center">
                        <v-subheader>Ödeme Şekli</v-subheader>
                      </v-col>
                      <v-col cols="12" md="3" class="pa-0">
                        <v-select
                          v-model="customerData.odemeSekli"
                          :items="odemeItems"
                       
                          outlined
                          dense
                          hide-details
                          :rules="[v => !!v || 'Bu alan zorunludur.']"
                        ></v-select>
                      </v-col>
                    </v-row>

                

                  <v-row align="center"  class="mb-0">
                    <v-col offset-md="3" cols="6" class="pa-0">
                      <v-btn :loading="loading" @click="submit" color="success" depressed class="white--text">
                        <span>Kaydet</span>                       
                        <v-icon dark right>
                          {{ icons.mdiCheckboxMarkedCircleOutline }}
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
        </v-row> 
    </div>


</div>
  </template>
  
  <script>
  import Vue from 'vue';
  import { mdiClose, mdiPlusThick,mdiFormatListChecks,mdiAccountMultiplePlus } from '@mdi/js';
  import useAppConfig from '@core/@app-config/useAppConfig';
  import { onMounted,ref} from '@vue/composition-api';
  import { postData } from '@utils'
  import store from '@/store'
   import CustomerList from '@/components/CustomerList.vue'
  export default {
    components: {     
      CustomerList,
    },
    
  setup(props) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const { isDark } = useAppConfig();    
      const odemeItems = ref([])
      const ilItems = ref([])
      const ilceItems = ref([])
      const customerData = ref({
      unvan: '',
      vergiNo: '',
      vergiDairesi: '',
      adres1: '',
      adres2: '',
      sehir: '',
      ilce: '',
      telefon: '',
      email: '',
      odemeSekli: ''
    });

    const odemeTipi = () => {
        postData({
            method: 'getMemberPaymentConditions',
            customer: store.state.plasiyerCustomer,
            }).then(response => {
            odemeItems.value = response.map(({ paymcondtxt, paymcond }) => ({
                text: paymcondtxt,
                value: paymcond,
            }));
            }).catch(error => {
            console.error("Ödeme Tipleri alınırken hata oluştu:", error);
            });
        };
        
    const getIl = () => {        
    postData({
        method: 'getCityList',           
    }).then(response => {
        console.log("API Yanıtı:", response); // API yanıtını kontrol et

        if (!response || !Array.isArray(response.response)) {
            throw new Error("Beklenen array formatında değil: " + JSON.stringify(response));
        }

        ilItems.value = response.response.map(({ key, value }) => ({
            text: value,
            value: key,
        }));
    }).catch(error => {
        console.error("İl bilgisi alınırken hata oluştu:", error);
    });
        };

    const  getIlce = () => {
      if (!customerData.value.sehir) {
        ilceItems.value  = [];
        return;
      }

      postData({
        method: "getDistrictList",
        city: customerData.value.sehir,
      })
        .then(response => {
          if (response.response) {
            ilceItems.value = response.response.map(({ id, ilce }) => ({
              text: ilce,
              value: id,
            }));
          }
        })
        .catch(error => console.error("İlçe bilgisi alınırken hata oluştu:", error));

    };  
  

        onMounted(() => {
            odemeTipi();
            getIl();
        });

      return {
        isDark,  
        userData  ,
        customerData,     
        odemeItems,
        ilItems,
        getIlce,
        ilceItems,
      };
    },
    data() {
      return {        
        loading: false,     
        datePicker: false,
        icons: {
          mdiClose,
          mdiPlusThick,
          mdiFormatListChecks,
          mdiAccountMultiplePlus
        },
      };
    },    
      
    methods: {
      async submit() {
        debugger
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
          Vue.swal({
            title: 'Hata',
            html: 'Lütfen zorunlu alanları doldurunuz',
            icon: 'error',
            background: '#FF4C51',
            timerProgressBar: true,
            showConfirmButton: true,
          });
        } else {
            const result = await Vue.swal({
                title: 'Onay',
                text: 'Kaydetmek istediğinize emin misiniz?',
                icon: 'question',
                buttons: true,
                dangerMode: true,
                });

            if (result === true) {
            try {
                debugger         
                    const response = await this.$store.dispatch('postMethod', {
                    method: 'saveOpenerMsg',
                    id: this.editMessageData && this.editMessageData.id ? this.editMessageData.id : null,
                    'fieldx[tr][stext]': this.messageData.stext,
                    'fieldx[tr][content]': this.messageData.content,
                    'field[is_active]': this.messageData.is_active ? 1 : 0,
                    
                    'field[create_datetime]': this.formatDate(new Date()),
                    });
        
                    if (response.error === 1) {
                    Vue.swal({
                        title: 'Hata',
                        html: response.msg,
                        icon: 'error',
                        background: '#FF4C51',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                    } else {
                    this.$emit('refetch-data')
                    this.$emit('update:is-add-update-sidebar-active', false)
        
                    
                    this.$emit('save-product-event', {
                        message: `Veri Girişi Başarılı`,
                        color: 'success',               
                        timeout: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                    this.loading = false             
                    }            
                } catch (error) {
                    console.error('Error during saving:', error.message || error);
                    Vue.swal({
                    title: 'Hata',
                    html: 'Bir hata oluştu, lütfen tekrar deneyin.',
                    icon: 'error',
                    background: '#FF4C51',
                    timerProgressBar: true,
                    showConfirmButton: true,
                    });
                }
            }
            else {
            // Kullanıcı onayı reddettiğinde yapılacak işlemler
            Vue.swal({
                title: 'İptal Edildi',
                text: 'Kaydetme işlemi iptal edildi.',
                icon: 'info',                
                timerProgressBar: true,
                showConfirmButton: false,
                timer: 2000
            });
            }
        }
      },

   
     
      close() {
        this.$refs.observer.reset();
        this.$nextTick(() => {
            this.messageData = {
                unvan: '',
                vergiNo: '',
                vergiDairesi: '',
                adres1: '',
                adres2: '',
                sehir: '',
                ilce: '',
                telefon: '',
                email: '',
                odemeSekli: ''
                };
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .input-field {
    margin-left: 20px; /* Sol boşluk */
    margin-bottom: 16px; /* Alt boşluk */
  }

  .custom-menu {
    z-index: 2000 !important;
    position: absolute !important;
    left: 50% !important; /* Menü soldan %50 uzaklıkta başlasın */
    transform: translateX(-50%); /* Ortalar */
  }
  </style>
  